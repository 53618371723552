import React, { useState, useEffect, useCallback, setState } from 'react';
import { useDropzone } from 'react-dropzone';

import UploadSVG from '../images/upload.inline.svg';
import './Scanner.css';
import utils from './Utils';
import useDebounce from './UseDebounce';
import Footer from './Footer';
// require("es6-promise").polyfill()
// require("isomorphic-fetch")

const scanStatus = {
  DEFAULT: 0,
  WRITING: 1,
  DRAGGING: 2,
  CHECKING: 3,
  DOWNLOADING: 4,
  REDIRECTING: 5,
  UPLOADING: 6,
};





const buttonText = (status, percent) => {
  switch (status) {
    case scanStatus.CHECKING:
      return 'Checking report...';
    case scanStatus.REDIRECTING:
      return 'Redirecting...';
    case scanStatus.UPLOADING:
      return `Uploading ${percent < 10 ? ' ' : ''}${percent}%`;
    case scanStatus.DOWNLOADING:
      return 'Getting File...';
    default:
      return 'Scan';
  }
};



const Scanner = () => {
  const [status, setStatus] = useState(scanStatus.DEFAULT);
  const [checked, setChecked] = React.useState(true);
  
  const [ytURL, setYTURL] = useState('');
  const [percent, setPercent] = useState(0);
  const [message, setMessage] = useState(null);
  const debouncedDrag = useDebounce(status, 500);
  const maxVideoSize = 100 * 1024 * 1024;

  useEffect(() => {
    window.ondragenter = (e) => {
      setStatus((prev) => {
        if (prev === scanStatus.DEFAULT) {
          return scanStatus.DRAGGING;
        }
        return prev;
      });
    };
    return () => {
      window.ondragenter = undefined;
    };
  }, []);

  useEffect(() => {
    if (!isDragActive && debouncedDrag === scanStatus.DRAGGING) {
      setStatus(scanStatus.DEFAULT);
    }

    if (status !== scanStatus.DEFAULT && status !== scanStatus.WRITING) {
      setYTURL('');
      setMessage(null);
    }
  });

  const redirectResultPage = (reportID) => {
    window.location.href = utils.getResultURL(reportID);
    setStatus(scanStatus.DEFAULT);
  };

  const scanVideo = (file) => {
    setStatus(scanStatus.UPLOADING);
    utils.scanVideo(
      file,
      (res) => {
        redirectResultPage(res['report-id']);
      },
      (res) => {
        setStatus(scanStatus.DEFAULT);
        setMessage(res.data?.Message || 'Something went wrong');
      },
      (err) => {
        setPercent(0);
      },
      (p) => {
        setPercent(p);
        if (p === 100) {
          setStatus(scanStatus.REDIRECTING);
        }
      }
    );
  };

  const onDrop = useCallback((files, reject) => {
    if (
      reject.length > 0 &&
      reject[0].file &&
      reject[0].file.size > maxVideoSize
    ) {
      setStatus(scanStatus.DEFAULT);
      setMessage('Your video file is too large. The limit is 100MB.');
      return;
    }

    if (!files || files.length === 0) {
      setStatus(scanStatus.DEFAULT);
      setMessage('Please select a video file!');
      return;
    }


    setStatus(scanStatus.CHECKING);
    utils.calcSha1(files[0]).then((sha1) => {
      utils.getReport(
        sha1,
        (res) => {
          setStatus(scanStatus.REDIRECTING);
          redirectResultPage(res['report-id']);
        },
        (res) => {
          if (res.status === 404) {
            scanVideo(files[0]);
            return;
          }

          setStatus(scanStatus.DEFAULT);
          setMessage(res.data?.Message || 'Something went wrong.');
        },
        (err) => {
          setStatus(scanStatus.DEFAULT);
          setMessage('Something went wrong.');
        }
      );
    });
  }, []);





  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onDrop,
    noClick: true,
    noKeyboard: true,
    accept: 'video/*',
    minSize: 0,
    maxSize: maxVideoSize,
    disabled: status !== scanStatus.DEFAULT && status != scanStatus.DRAGGING,
    onFileDialogCancel: () => {
      setStatus(scanStatus.DEFAULT);
    },
  });

  const scanOnClick = (e) => {
    e.preventDefault();
    let ytID = "";

    if(!checked){
      setMessage('To continue scanning, please check the box above.');
      return;
    }


    if (!ytURL) {
      setMessage('Make sure to enter a valid video link.');
      return;
    }

    if (
      ytURL.includes('youtube') === false &&
      ytURL.includes('youtu.be') === false
    ) {
      // setMessage('Sorry, we only support YouTube links for now.');
      // return;
      ytID = ytURL;

    }
    else {
      ytID = utils.getYoutubeID(ytURL);
    }

    if (!ytID) {
      setMessage('Make sure to enter a valid video link.');
      return;
    }



    setStatus(scanStatus.CHECKING);
    utils.getUrlVideoReport(
      ytID,
      (res) => {
        setStatus(scanStatus.REDIRECTING);
        redirectResultPage(res['report-id']);
      },
      (res) => {
        if (res.status === 404) {
          setStatus(scanStatus.DOWNLOADING);
          utils.scanYT(
            ytID,
            (res) => {
              redirectResultPage(res['report-id']);
            },
            (res) => {
              setStatus(scanStatus.DEFAULT);
              setMessage(res.data?.Message);
            },
            (err) => {
              setPercent(0);
            }
          );
          return;
        }

        setStatus(scanStatus.DEFAULT);
        setMessage(res.data?.Message || 'Something went wrong.');
      },
      (err) => {
        setStatus(scanStatus.DEFAULT);
        setMessage('Something went wrong.');
      }
    );
  };

  function Checkbox() {

    return (
      <label className="aBeta checkText">
        <input type="checkbox"
          defaultChecked={checked}
          onChange={() => setChecked(!checked)}
          style={{ marginRight: '5px' }}
        />
       
      </label>
    );
  }
  return (
    <div>
      <section className="scanner">
        <div className="container">
          <div className="row">
            <div
              className={`col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8
                col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4`}
            >
              <form className="scanner-form">
                <div
                  className={`drop-field-bg ${status === scanStatus.DRAGGING ? 'dragging' : ''
                    }`}
                  onClick={() => setStatus(scanStatus.DEFAULT)}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {isDragActive && (
                    <div className="drop-field">Drop the video here ...</div>
                  )}
                </div>
                <div className="scanner-header">
                  <h3>
                    Scan &amp; Detect <br className="visible-xs-block" /> Deepfake
                    Videos
                  </h3>
                  <p>Place a video link or upload a video</p>
                </div>
                <div className="searchbar">
                  <input
                    className="search_input yt-url"
                    type="url"
                    value={ytURL}
                    autoComplete="off"
                    autoCorrect={'off'}
                    autoCapitalize={'off'}
                    spellCheck={'false'}
                    autoFocus={''}
                    placeholder={'https://www.example.com/'}
                    readOnly={
                      status !== scanStatus.DEFAULT &&
                      status !== scanStatus.WRITING
                    }
                    disabled={
                      status !== scanStatus.DEFAULT &&
                      status !== scanStatus.WRITING
                    }
                    tabIndex={
                      status !== scanStatus.DEFAULT &&
                        status !== scanStatus.WRITING
                        ? '-1'
                        : ''
                    }
                    onChange={(e) => {
                      setYTURL(e.target.value);
                      setMessage(null);
                      if (e.target.value) {
                        setStatus(scanStatus.WRITING);
                        return;
                      }
                      setStatus(scanStatus.DEFAULT);
                    }}
                  />
                  <div className="search_icon" title="Upload File">
                    {status !== scanStatus.WRITING ? (
                      <UploadSVG
                        disabled={status !== scanStatus.DEFAULT}
                        onClick={() => {
                          if (status === scanStatus.DEFAULT) {
                            open();
                          }
                        }}
                      />
                    ) : (
                      <div
                        onClick={() => {
                          setStatus(scanStatus.DEFAULT);
                          setYTURL('');
                          setMessage(null);
                        }}
                      >
                        ×
                      </div>
                    )}
                  </div>
                </div>
                <center title="Scan" style={{ paddingTop: '10px' }}>
                  {/* <a href="https://github.com/deepware/deepfake-scanner" className="aBeta" target="_blank" >Deepware Scanner is still in Beta</a><br></br>
                  <br></br> */}
                  <Checkbox/> 
                 <span className="sBeta"> By submitting data, you are agreeing to <a href="https://deepware.ai/terms-of-services/" className="aBeta" target="_blank">Terms of Services</a> and <a href="https://deepware.ai/privacy-policy/" className="aBeta" target="_blank">Privacy Policy</a></span>
                <br></br>
                  <button
                    className={`progress-button ${status === scanStatus.UPLOADING ? 'state-loading' : ''
                      }`}
                    disabled={
                      (status !== scanStatus.DEFAULT &&
                        status !== scanStatus.WRITING)
                    }
                    onClick={scanOnClick}
                  >
                    <span className="content">{buttonText(status, percent)}</span>
                    <span className="btnBetaText">BETA</span>
                    <span className="progress">
                      <span
                        className={`progress-inner ${status !== scanStatus.UPLOADING ? 'notransition' : ''
                          }`}
                        style={{ width: `${percent}%`, opacity: '1' }}
                      ></span>
                    </span>
                  </button>
                  <div className="text-danger" style={{  fontWeight: '400', fontSize: '13px'}}>
                    {message === null ? <>&nbsp;</> : message}
                  </div>
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer fixed={true} />
    </div>
  );
};

export default Scanner;