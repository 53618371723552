import React, {useState} from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Scanner from '../components/Scanner';
import Footer from '../components/Footer';
import './index.css';
import './wp-style.css';
import './wp-main.css';
 
const IndexPage = () => (
  <Layout>
    <SEO title="Deepware" />
    <Scanner />
  </Layout>
);

export default IndexPage;
